import axios from "axios";
import { authStoreActions } from "@/store/modules/auth/constNames";
import { useStore } from "../composables/useStore";
import { userInfoStoreActions } from "../store/modules/userInfo/constNames";

export const baseConfig = {
  baseURL: process.env.VUE_APP_API,
  headers: {
    "Content-Type": "application/json",
    Client: "web_sales",
    Site: process.env.VUE_APP_SITE,
  },
};

export const axiosInstance = axios.create(baseConfig);

axiosInstance.interceptors.response.use(
  (res) => {
    const { refresh, token, ...resData } = res.data;
    if (token && refresh) {
      const { dispatchAction } = useStore();
      dispatchAction(authStoreActions.AUTH_LOGIN, { token, refresh });
      dispatchAction(userInfoStoreActions.SAVE_USER, resData);
    }
    return { ...res, data: resData };
  },
  (err) => {
    if (err.response.status === 401) {
      const { dispatchAction } = useStore();
      dispatchAction(authStoreActions.AUTH_LOGOUT);
      dispatchAction(userInfoStoreActions.REMOVE_USER);
      !window.location.pathname.includes("auth") &&
        window.location.replace("/");
    }
    return Promise.reject(err.response);
  }
);
