<template>
  <v-fade-transition mode="out-in">
    <router-view />
  </v-fade-transition>
</template>

<script>
import { i18n } from "@/plugins/i18n";

export default {
  name: "App",
  metaInfo: {
    title: i18n.t("loading"),
    titleTemplate: "%s | Foodeo",
    htmlAttrs: { lang: i18n.locale },
    meta: [
      { charset: "utf-8" },
      { name: "viewport", content: "width=device-width, initial-scale=1" },
    ],
  },
};
</script>
