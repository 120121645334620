export const useStorageUtils = (storage = "localStorage") => {
  const useStorage = storage === "localStorage" ? localStorage : sessionStorage;
  const saveItem = (key, data) => {
    const isObject = typeof data === "object";
    useStorage.setItem(key, isObject ? JSON.stringify(data) : data);
  };
  const getItem = (key) =>
    useStorage.getItem(key) && JSON.parse(useStorage.getItem(key));

  const removeItem = (key) => useStorage.removeItem(key);

  const clear = () => useStorage.clear();

  return { clear, saveItem, getItem, removeItem };
};
