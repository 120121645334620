export const esOrderLocale = {
  LOCAL: "a Mesa",
  AT_HOME: "a Domicilio",
  TO_PICK_UP: "a Recoger",
  badSettingOrder:
    "Si aparece este mensaje puede ser debido a una mala configuración de su orden, presione el botón de cambiar tipo de orden para rectificar la misma",
  scanQrIsRequiredForOrderType:
    "Para realizar este tipo de pedido debes escanear el QR de su mesa",
  phoneIsRequiredForOrderType:
    "Para seguir adelante con este tipo de pedido, necesitamos que antes configures tu teléfono",
  authIsRequiredForOrderType:
    "Para proceder con este tipo de pedido, necesitamos que inicies session",
  finishOrder: "finalizar pedido",
  selectAddress: "seleccionar dirección",
  changeAddress: "cambiar dirección",
  domicileSupplement: "Suplemento a Domicilio",
};
